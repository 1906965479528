.addTask{
  padding: 5px;
  font-size: 32px;
  height: 40%; /* Increase the height */
  width: 80%; /* Increase the width */
  margin-bottom: 50px; /* Add margin at bottom */
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(1, 41, 11, 0.6);
}
.searchTask{
  padding: 5px;
  font-size: 28px;
  height: 40%; /* Increase the height */
  width: 80%; /* Increase the width */
  margin-top: 10px; /* Add margin at bottom */
  margin-bottom: 10px; /* Add margin at bottom */
  margin-left: 5px;
  box-shadow: 2px 2px 8px rgba(1, 41, 11, 0.6);
}
.dueDate{
color: orange;
}
.app_button {
  padding: 1px 1px;
  margin-left: 8px;
  border: 1px solid black;
  background-color: #e8ebef;
  color: rgb(23, 82, 192);
  box-shadow: 2px 2px 8px rgba(34, 33, 33, 0.4);
  cursor: pointer;
  font-size: 25px;
}

.app_button_selected {
  padding: 2px 2px;
  margin-left: 7px;
  font-size: 26px;
  border: 1px solid black;
  background-color: rgb(28, 159, 59);
  color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  cursor: pointer;
  font-size: 20px;
}

.app_button_delete_selected {
  padding: 1px 1px;
  margin-left: 10px;
  background-color: rgb(244, 107, 107);
  color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  cursor: pointer;
  font-size: 18px;
}


.wide_app_button {
  padding: 2px 2px;
  margin-left: 8px;
  border: 3px solid black;
  background-color: #e8ebef;
  color: rgb(23, 82, 192);
  box-shadow: 4px 4px 8px rgba(34, 33, 33, 0.4);
  cursor: pointer;
  width: 20%;
  font-size: 21px;
}

.wide_app_button_selected {
  padding: 2px 2px;
  margin-left: 7px;
  font-size: 26px;
  border: 1px solid black;
  background-color: rgb(28, 159, 59);
  color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  cursor: pointer;
  font-size: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-right: 0;
  width: 100%; 
  font: 24px Arial, sans-serif;
}

li {
  margin-bottom: 1px;
  width: 100%; /* Add this line */
}

.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.completed {
  text-decoration: line-through;
  color: gray;
}


.inputtextbox
{
  padding: 5px;
  font-size: 16px;
  height: 30%; /* Increase the height */
  width: 55%; /* Increase the width */
  margin-left: 5px;
  border: 1px solid black;
}
.app_button_addbutton {
  background-color:rgb(16, 130, 16);
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(1, 55, 20, 0.8);
  width: 10%; /* Increase the width */
  height: 48px; /* Increase the height */
}

.app_button_signoutbutton {
  background-color: orange;
  margin-left: 10px;
  padding: 5px 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  font-size: 18px; /* make the button text extremely small */
}
.textbutton {
  background-color: grey;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 5px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
  font-size: 18px; /* make the button text extremely small */
}

.markcompletebutton {
  background-color: rgb(246, 249, 249);
  border: black;
  border: 2px solid black;
  color: rgb(21, 124, 69);
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  padding: 1px 1px;
  box-shadow: 2px 2px 4px rgba(23, 2, 2, 0.953);
  font-size: 18px; /* make the button text extremely small */
}

.donemarkcompletebutton {
  background-color: rgb(6, 88, 28);
  border: black;
  border: 2px solid black;
  color: rgb(252, 254, 253);
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  padding: 1px 1px;
  box-shadow: 2px 2px 4px rgba(23, 2, 2, 0.953);
  font-size: 18px; /* make the button text extremely small */
}
.showcompletedbutton {
  background-color: rgb(239, 216, 14);
  border: none;
  color: black;
  cursor: pointer;
  margin-bottom: 110px;
  padding: 10px 10px;
  font-size: 16px; /* make the button text extremely small */
}
.textbutton:hover {
  background-color: #555;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}
.signoutbutton:hover {
  background-color: #555;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}
.textinput {
  padding: 2px;
  font-size: 16px;
  height: 30%; /* Increase the height */
  width: 65%; /* Increase the width */
  margin-left: 5px;
  border: 1px solid #070707;
  box-shadow: 2px 2px 8px rgba(4, 4, 4, 0.8);
}

.signonpagebutton {
  background-color: rgb(58, 111, 58);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}

.signuppagebutton {
  background-color: rgb(148, 118, 9);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}

.signgooglepagebutton {
  background-color: rgb(31, 115, 211);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}
.editForm {
  position: fixed;
  padding: 10px;
  width: 60%;
  bottom: 30%;
  left: 40%;
  transform: translate(-50%, -50%);
  border: 2px solid;
  background-color: whitesmoke;
  box-shadow: 2px 4px 12px rgb(7, 7, 7);
  margin-bottom: 5px; /* Move the text box up 10 pixels */
  font-size: 18px;
}


